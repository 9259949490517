<template>
  <div class="min-h-60">
    <b-card class="form-data">
      <b-row>
        <b-col md="6" style="min-height: 100%">
          <b-card style="min-height: 100%">
            <b-form-group
              label="Digite o e-mail para convidar:"
              class="list-invites mb-4"
              :description="
                $v.email.$error
                  ? 'Formato de e-mail inválido ex: email@email.com'
                  : ''
              "
            >
              <b-form-input
                type="email"
                @input="handlegetEmailsProviders"
                placeholder="email@email.com"
                v-model="$v.email.$model"
                :class="$v.email.$error && 'is-invalid'"
                trim
              >
              </b-form-input>

              <small class="text-danger" v-if="invitesHasRegistred.length">
                Os seguintes e-mails já foram adicionados:
                <strong>{{ invitesHasRegistred.join(", ") }}</strong>
              </small>
            </b-form-group>
            <div v-if="hasInvited" class="text-center p-5">
              <b-icon
                icon="exclamation-triangle-fill"
                variant="warning"
                class="h1"
              />
              <p>Este e-mail já foi adicionado</p>
            </div>
            <div v-else-if="sugestEmails.length > 0">
              <span class="d-block mb-2">Sugestões:</span>
              <div class="list-invites-invite">
                <b-card
                  v-for="(provider, index) in sugestEmails"
                  :key="index"
                  no-body
                  class="py-2 px-3 mb-2"
                >
                  <section
                    class="d-flex align-items-center justify-content-between"
                  >
                    <section class="d-flex align-items-center">
                      <section>
                        <strong class="d-block">
                          {{ provider.social_reason }}
                        </strong>
                        <small class="d-flex align-items-center">
                          <b-icon icon="wallet" class="mr-2 mb-0" />
                          <span> {{ provider.segment || "-" }}</span>
                        </small>
                        <small>
                          <b-icon icon="envelope" class="mr-2 mb-0" />
                          {{ provider.email }}
                        </small>
                      </section>
                    </section>
                    <b-button
                      @click="handleAddEmail(provider)"
                      size="sm"
                      class="m-0 d-flex align-items-center"
                      variant="outline-primary"
                    >
                      Adicionar
                    </b-button>
                  </section>
                </b-card>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col md="6" style="min-height: 100%">
          <strong class="d-block mb-2">Listagem de convidados</strong>
          <div class="list-invites-sugest">
            <b-card
              v-for="(invite, index) in invites"
              :key="index"
              no-body
              class="py-2 px-3 mb-2"
            >
              <section
                class="d-flex align-items-center justify-content-between"
              >
                <section class="d-flex align-items-center">
                  <b-icon icon="envelope" class="mr-3 h5 mb-0" />
                  <section style="line-height: 1">
                    <small class="d-block my-1">
                      {{ invite.social_reason || invite.email }}
                    </small>
                    <small class="d-block">
                      Data do convite: {{ invite.updated_at | dateFull }}</small
                    >
                    <div class="d-flex mt-2 gap-1">
                      <small class="text-danger" v-if="invite.is_expired">
                        Expirado
                      </small>
                      <small v-if="!invite.is_expired">
                        {{ status[invite.status] }}
                      </small>
                      <small class="text-muted" v-if="!invite.id">
                        Aguardando envio
                      </small>
                    </div>
                  </section>
                </section>
                <div v-if="invite.status != 2" class="d-flex gap-2">
                  <b-button
                    :disabled="resendLoading"
                    v-if="invite.id"
                    @click="handleResendInvite(invite)"
                    size="sm"
                    class="m-0 d-flex align-items-center gap-1"
                    variant="outline-info"
                  >
                    <b-icon icon="envelope" />
                    <span>Reenviar</span>
                  </b-button>
                  <b-button
                    @click="handleRemoveEmail(index)"
                    size="sm"
                    class="m-0 d-flex align-items-center gap-1 p-3"
                    variant="outline-danger"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </div>
              </section>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div class="bottom-navigation-service">
      <b-button variant="light" @click="handleGoBack()">
        <span>Voltar</span>
      </b-button>
      <div class="d-flex align-items-center">
        <b-button
          variant="outline-primary"
          class="mr-4"
          :disabled="loadingSubmit"
          :to="`/homologation/${this.homologationId}/service/list`"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Concluir edição</span>
        </b-button>
        <b-button
          @click="handleSubmit()"
          variant="primary"
          :disabled="loadingSubmit"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>{{ $t("general.save") }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from "@/services/api";
import { validationMixin } from "vuelidate";
import { email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { isValidEmail } from "@/utils/validations/email";
import _ from "lodash";

export default {
  mixins: [validationMixin],

  data() {
    return {
      search: null,
      emailTextError: "Formato de e-mail inválido",
      invitesHasRegistred: [],
      homologationId: null,
      serviceId: null,
      show: true,
      hasInvited: false,
      resendLoading: false,
      loadingSubmit: false,
      invites: [],
      sugestEmails: [],
      email: "",
      status: {
        1: "Pendente",
        2: "Aceito",
        3: "Recusado",
      },
    };
  },
  validations: {
    email: {
      email,
    },
  },
  methods: {
    ...mapActions([
      "get_service_scope",
      "show_toast",
      "get_all_provider",
      "resend_invite",
    ]),

    handlerIsValidEmail(email) {
      return isValidEmail(email);
    },

    handleSubmit() {
      this.loadingSubmit = true;
      let path = "/service-scope/";
      let funcApi = api.post;
      if (this.serviceId) {
        path = `/service-scope/${this.serviceId}/?service_id=${this.serviceId}`;
        funcApi = api.patch;
      }
      const emails = this.invites.flatMap(({ email }) => [email]);

      funcApi(path, { emails })
        .then((response) => {
          this.loadingSubmit = false;
          let type = "";
          let message = "";

          if (!this.serviceId) {
            this.serviceId = response.data.id;
          }

          if ([200, 201].includes(response.status)) {
            type = "success";
            message = "Convites enviados";
            this.$router.push(
              `/homologation/${this.homologationId}/service/list`
            );
          } else {
            type = "error";
            message = response.statusText;
          }
          this.show_toast({
            message,
            type,
          });
        })
        .catch(() => {
          this.loadingSubmit = false;
        });
    },

    handleRemoveEmail(index) {
      this.invites.splice(index, 1);
    },

    handleResendInvite(invite) {
      this.resendLoading = true;
      this.resend_invite(invite.id).then(() => {
        this.resendLoading = false;
        this.show_toast({
          message: "Convite reenviado com sucesso",
          type: "success",
        });
        this.getEdit();
      });
    },

    handleAddEmail({ social_reason, email }) {
      this.email = "";
      this.invites = [
        ...this.invites,
        { email, social_reason, updated_at: new Date() },
      ];
      this.sugestEmails = this.sugestEmails.filter(
        (sugest) => email !== sugest.email
      );
    },

    getEmailsProviders(email) {
      this.get_all_provider({ search: email }).then(({ results }) => {
        if (results?.length > 0) {
          this.sugestEmails = results
            .flatMap(({ social_reason, email, segment }) => {
              if (social_reason) {
                return {
                  social_reason,
                  segment,
                  email,
                };
              }
              return [];
            })
            .filter(({ email }) => !this.invites.includes(email));
        } else if (email) {
          this.sugestEmails.push({
            social_reason: `Sem cadastro | ${email}`,
            email,
          });
        }
      });
    },

    handlegetEmailsProviders: _.debounce(function(value) {
      this.sugestEmails = [];
      const _invites = this.invites.flatMap(({ email }) => [email]);

      if (_invites.includes(this.email)) {
        this.hasInvited = true;
        return;
      }

      this.hasInvited = false;
      if (isValidEmail(value) || value === "") {
        this.getEmailsProviders(value);
      }
    }, 500),

    handleGoBack() {
      this.$router.push(
        `/homologation/${this.homologationId}/service/${this.serviceId}/edit/premises/`
      );
    },

    getEdit() {
      this.get_service_scope({ id: this.serviceId }).then((data) => {
        this.form = data;
        this.invites = this.form.notifications;
        this.form.dependency_limit_perc = `${String(
          this.form.dependency_limit_perc
        )}%`;
        this.form.contract_value_mensal = `R$ ${String(
          this.form.contract_value_mensal
        )}`;
      });
    },
  },
  mounted() {
    this.homologationId = this.$route.params.homologation_id;
    this.serviceId = this.$route.params.service_id;
    if (this.serviceId) {
      this.getEdit();
    }
    this.getEmailsProviders("");
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
<style scoped>
.list-invites-invite {
  height: 40vh;
  overflow: auto;
}
.list-invites-sugest {
  height: 50vh;
  overflow: auto;
}
</style>
